<template>
  <div class="flex">
    <div>
      <el-form
        :model="Form"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        v-loading="loading"
      >
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="登录账户:" prop="account">
              <el-input v-model="Form.account"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="登录密码:" prop="pwd">
              <el-input v-model="Form.pwd"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数字通证名称:" prop="tokenName">
              <el-input v-model="Form.tokenName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数字通证英文名称:" prop="tokenEnglish">
              <el-input v-model="Form.tokenEnglish"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数字通证编号:" prop="tokenNum">
              <el-input v-model="Form.tokenNum"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="通证转出时间:" prop="transferTime">
              <el-input v-model="Form.transferTime" placeholder="格式：09:00:00-17:00:00"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="划拨确认时间:" prop="affirmTime">
              <el-input v-model="Form.affirmTime" placeholder="格式：09:00:00-17:00:00"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="归集账户地址:" prop="wallet">
              <el-input v-model="Form.wallet"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="协议区价格:" prop="agreementPrice">
              <el-input v-model="Form.agreementPrice"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="原价区价格:" prop="price">
              <el-input v-model="Form.price"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="退回钱包天数" prop="backTime">
              <el-input v-model="Form.backTime"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="区块时间:" prop="blocksTime">
              <el-input v-model="Form.blocksTime"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="核心算法:" prop="coreAlgorithm">
              <el-input v-model="Form.coreAlgorithm"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开发者:" prop="developer">
              <el-input v-model="Form.developer"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="总量:" prop="gross">
              <el-input v-model="Form.gross"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发行日期:" prop="issuingDate">
               <el-date-picker
                v-model="Form.issuingDate"
               
                type="datetime"
                placeholder="选择日期时间"
                style="width: 365px"
                value-format="timestamp"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          
          <el-col :span="12">
            <el-form-item label="扣除差价百分比:" prop="rate">
              <el-input v-model="Form.rate"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="锁仓天数:" prop="rockTime">
              <el-input v-model="Form.rockTime"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公钥:" prop="secret">
              <el-input v-model="Form.secret"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商场cny通知地址:" prop="cnyUrl">
              <el-input v-model="Form.cnyUrl"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="涨跌幅比例:" prop="closePriceRate">
              <el-input v-model="Form.closePriceRate"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="划拨折扣:" prop="agreementRate">
              <el-input v-model="Form.agreementRate"></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="商城流入百分比:" prop="shopRate">
              <el-input v-model="Form.shopRate"></el-input>
            </el-form-item>
          </el-col>
          
          <el-col :span="12">
            <el-form-item label="开盘状态:" prop="openStatus">
              <el-radio-group v-model="Form.openStatus">
                <el-radio :label="1">开盘</el-radio>
                <el-radio :label="2">不开盘</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开盘时间:" prop="rockTime">
              <el-input v-model="Form.openTime" placeholder="格式：09:00:00-11:30:00,13:00:00-15:00:00"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="上传图片:">
              <el-upload
                class="avatar-uploader"
                action=""
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :http-request="mainPicUpload"
              >
                <img v-if="Form.image" :src="Form.image" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态:" prop="status">
              <el-radio-group v-model="Form.status">
                <el-radio :label="0">上线</el-radio>
                <el-radio :label="1">下线</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="">
        <el-button type="primary" style="margin-left: auto" @click="submit()"
          >提交</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Form: {},
      rules: {},
      loading: false,
      id: "",
      imageUrl:{}
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getInfo();
    }
  },
  methods: {
    getInfo() {
      this.axios.get("/admin/gen/findById", { id: this.id }).then((res) => {
        this.Form = res.data.data;
      });
    },
    submit() {
      this.loading = true;
      if (this.id) {
        this.axios.put("/admin/gen/updateGen", this.Form).then((res) => {
          this.loading = false;
          if (res.code == 200) {
            this.$router.push("gen");
          } else {
            this.$message(res.mesg);
          }
        });
      } else {
        this.axios.post("/admin/gen/addGen", this.Form).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.$router.push("gen");
          } else {
            this.$message(res.data.mesg);
          }
        });
      }
    },
    // 上传图片
    mainPicUpload(options) {
      this.loading = true
      function dataUrl(data) {
        let arr = data.split('&')
        let list = {}
        arr.forEach(item => {
          let itm = item.split('=')
          list[itm[0]] = decodeURIComponent(itm[1])
        })
        return list
      }
      var { file } = options
      let data
      this.axios.get('/getOSSKey', {}).then(res => {
        data = res.data.data
        var ossData = new FormData()
        ossData.append('name', file.name)
        let filename = `${Date.parse(new Date())}` + file.name //图片名称
        let keyValue = data.dir + filename
        //请求数据
        ossData.append('key', keyValue)
        ossData.append('callback', data.callback)
        ossData.append('policy', data.policy)
        ossData.append('OSSAccessKeyId', data.accessid)
        ossData.append('success_action_status', 200)
        ossData.append('signature', data.signature)
        ossData.append('file', file)
        this.axios.post(data.host, ossData).then(res => {
          this.imageUrl = dataUrl(res.data.Status)
          this.imageUrl = this.imageUrl.filename
          this.Form.image = this.imageUrl
          this.loading = false
        })
      })
    },

    handleAvatarSuccess(res, file) {
      console.log(res, file)
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  padding: 50px;
  width: 1000px;
  display: flex;
  // background-color: red;
  height: 200px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border-radius: 5px;
  border: 1px dashed #8c939d;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
  border-radius: 5px;
}
</style>